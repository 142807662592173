import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Infrastructure = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Infrastructure Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="backupContainer">
        <div className="cloudService1">
          <h2 className="headText">Infrastructure</h2>
          <p className="pText">
            Functioning as a one-stop shop for all your IT needs, we help in
            identifying, integrating and installing data centers, networking,
            computing devices, server, storage and virtualization.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-gear circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Hardware Infrastructure Services
                  </h4>
                  <p className="cardText">
                    Hardware infrastructure services are a range of services
                    that support and manage an organization's IT environment.
                    These services can include: Cloud computing, Network
                    security, Data backup and disaster recovery, End-user
                    support services, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-brands fa-hive circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Software Infrastructure Services
                  </h4>
                  <p className="cardText">
                    Software infrastructure services include:Computer network,
                    Saas, Paas, Cloud and infrastructure security, Cloud
                    computing, Database platforms, Cloud migration, Service
                    management, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-cloud circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Cloud Infrastructure Services</h4>
                  <p className="cardText">
                    the hardware and software components that make up the cloud,
                    and allow organizations to access computing resources
                    without investing in their own infrastructure: Components:
                    Cloud infrastructure includes hardware, storage, networking,
                    compute, security, management, and virtualization.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-network-wired circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Network Services</h4>
                  <p className="cardText">
                    LAN & WAN: The infrastructure that connects devices within
                    an organization and between remote locations. Internet
                    Connectivity: High-speed internet access and connections to
                    cloud or remote data centers. VPN: Secure remote access to
                    internal networks. Network Security: Services like
                    firewalls, intrusion detection/prevention systems, and VPNs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-brands fa-soundcloud circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Virtualization </h4>
                  <p className="cardText">
                    VMs Virtualized environments that run on physical servers
                    but act as independent systems. Containers: Lightweight,
                    portable environments (eg. Docker) used to package and run
                    applications in isolated environments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-gauge circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">IT Service Management </h4>
                  <p className="cardText">
                    Change Mgmt: Processes to control changes in IT
                    infrastructure to minimize risk and ensure compliance.
                    Incident Mgmt: Resolving IT incidents (e.g., system outages
                    or technical issues) as quickly as possible to minimize
                    downtime.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="imageContainer">
        <div className="backupText">
          <h3 className="h3Text">Know More about Infrastructure Services</h3>
          <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to
            unlock their growth potential and uncover newer avenues for their
            business.
          </p>
        </div>
        <div className="row">
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/infra1.jpg" alt="img1" />
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/infra2.png" alt="img2" />
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/infra3.webp" alt="img3" />
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/infra4.png" alt="img4" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Infrastructure;
