import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Analytics = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Development Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="container">
        <div className="cloudService4">
        <h2 className="headText">Application Development</h2>
          <p className="pText mt-3">
          . These services focus on building applications for different platforms, including web, mobile, desktop, and cloud environments, and can range from simple tools to complex, enterprise-grade systems.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-laptop-code circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Web Application Development</h4>
                  <p className="cardText">
                  Focuses on responsive design to ensure applications are optimized across devices (desktop, tablet, mobile).
                  A strong focus on making the application visually appealing and user-friendly, improving adoption and user satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-file-code circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Custom Application Development
                  </h4>
                  <p className="cardText">
                  	Tailored solutions designed specifically for a business or user’s unique needs.
                    	These applications are often developed to integrate with other systems or platforms, providing seamless operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-code circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">UI/UX Design Services</h4>
                  <p className="cardText">
        	UI : Designing the look and feel of the application, focusing on creating an aesthetically pleasing and functional interface.
UX : Ensuring the application is intuitive, easy to navigate, and optimized for the user’s needs. This can include user research, wire framing,
 prototyping, and usability testing.	

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-shield-halved circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Software as a Service (SaaS) Development</h4>
                  <p className="cardText">
                  SaaS applications are hosted on the cloud and delivered over the internet, enabling businesses to access software
                   without the need to manage infrastructure or software updates.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-brands fa-medapps circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Application Integration</h4>
                  <p className="cardText">
                  Developing software that enables applications, databases, and systems to work together by exchanging data and functionality.
                  Involves building APIs to facilitate communication between systems.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-thumbs-up circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Testing and Quality Assurance </h4>
                  <p className="cardText">
                  Unit Testing: Testing individual components or units of the application to ensure they function correctly.
                	Integration Testing: Ensuring that different components of the application work together smoothly.

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cloudService3">

          <div className="imageContainer">
        <div className="backupText">
          <h3 className="h3Text">
           Know more about Application Development
          </h3>
          <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to
            unlock their growth potential and uncover newer avenues for their
            business.
          </p>
        </div>
        <div className="row">
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/app1.webp" alt="img1" />
         
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/app2.webp" alt="img2" />
       
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/app3.png" alt="img3" />
       
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/app4.webp" alt="img4" />
        
          </div>
        </div>
      </div>
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Analytics;
