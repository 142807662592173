import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Datacenter = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Data Center Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="container">
        <div className="cloudService4 dataWrapper">
          <h2 className="headText">Data Center</h2>
          <p className="pText">
          data center is a physical or virtual facility used to house critical applications, data storage systems, 
          servers, networking hardware, and other IT infrastructure components that support the operation of business applications and services. Data center services aim to ensure that the infrastructure is secure, reliable, scalable, and available for continuous business operations.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-database circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Optimized Data Center Infrastructure
                  </h4>
                  <p className="cardText">
                    Our services continue beyond delivering on service level
                    agreements. We also focus on providing desired outcomes with
                    all your data center needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-arrows-left-right circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Streamlined Operations</h4>
                  <p className="cardText">
                    Manage all operations of your data center environment with
                    scalable infrastructure solutions tailored to your business
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-box-archive circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Managed Services for Servers & Storage
                  </h4>
                  <p className="cardText">
                    With reliable and scalable storage infrastructure, you can
                    enhance your data performance and take care of the
                    operational management of data center servers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-database circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Cloud Hosting
                  </h4>
                  <p className="cardText">
                  Cloud hosting involves hosting applications, websites, and data on virtualized resources in a data center.
                   These resources are scalable and can be quickly provisioned and decommissioned to meet business demands.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-arrows-left-right circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Data Storage and Backup Services</h4>
                  <p className="cardText">
                  Data storage services involve securely storing business data on centralized storage systems 
                  within a data center. Backup services ensure the integrity and availability of data in case of hardware failure or disasters.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-box-archive circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Disaster Recovery (DR) Services
                  </h4>
                  <p className="cardText">
                  Disaster recovery services ensure that a business can recover its IT infrastructure,
                   data, and applications in case of a major system failure, natural disaster, or other unexpected events.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="imageContainer">
      <h3 className="h3Text">
      Know More about Data Center  Services
            </h3>
            <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to unlock their growth potential and uncover newer avenues for their business.
            </p>
      
            <div className="row">
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/DCS1.png" alt="dcs1" />
              
              </div>
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/DCS2.webp" alt="dcs2" />
         
              </div>
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/DCS3.webp" alt="dcs3" />
            
              </div>
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/DCS4.jpg" alt="dcs4" />
            
              </div>
            </div>
     </div>
      <Footer />
    </>
  );
};

export default Datacenter;
