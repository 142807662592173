import "../styles/section6.css";
import {Link} from 'react-router-dom';

const Section6 = () => {
  return (
    <>
      <div className="gallery">
        <figure className="grid">
          <img src="/s1.jpg"  alt="img1"
          />
           <h5 className="textHide">Application</h5>
          <figcaption>
            <h2>
              <span>Application</span> Service
            </h2>
            <p>	Development, maintenance, and management of software applications.</p>
            <Link to="/service/analytics"></Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="/s2.png"  alt="img2" />
          <h5 className="textHide">Infrastructure</h5>
          <figcaption>
            <h2>
              <span>Infrastructure</span> Service
            </h2>
            <p>
            Management of physical/virtual infrastructure, data centers, networks, and cloud services.
            </p>
            <Link to="/service/backup">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="/s3.webp" alt="img3"
          />
           <h5 className="textHide">Cloud</h5>
          <figcaption>
            <h2>
            <span>Cloud</span> Service
            </h2>
            <p>
            Providing cloud-based storage, compute, and migration services.
            </p>
            <Link to="/service/cloud">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img   src="/s4.webp"  alt="img4"
          />
           <h5 className="textHide">Network</h5>
          <figcaption>
            <h2>
            <span>Network </span> Service
            </h2>
            <p>
            Firewalls, intrusion detection/prevention systems, and VPNs to protect the organization’s network.
            </p>
            <Link to="/service/network">.</Link>
          </figcaption>
        </figure>
      </div>
      <div className="gallery">
        <figure className="grid">
          <img src="s5.jpg" alt="img5"
          />
           <h5 className="textHide">Security</h5>
          <figcaption>
            <h2>
            <span>Cybersecurity</span> Service
            </h2>
            <p>Protection of IT systems, networks, and data from cyber threats.</p>
            <Link to="/service/security">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="s7.webp" alt="img6"
          />
           <h5 className="textHide">Data Analytics</h5>
          <figcaption>
            <h2>
              <span>Data Analytics </span> Service
            </h2>
            <p>
            Collecting, analyzing, and making sense of data to support decision-making.
            </p>
            <Link to="/service/infrastructure">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="s8.webp"  alt="img7"
          />
           <h5 className="textHide">IT Consulting </h5>
          <figcaption>
            <h2>
           <span>IT Consulting </span> Service
            </h2>
            <p>
            Guidance and expertise in planning, strategizing, and implementing IT solutions.
            </p>
            <Link to="/service/virtualization">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="s6.jpg" alt="img8"
          />
           <h5 className="textHide">Support </h5>
          <figcaption>
            <h2>
            <span>IT Support </span> Service
            </h2>
            <p>
            Providing troubleshooting and technical support to end users.
            </p>
            <Link to="/service/licensing">.</Link>
          </figcaption>
        </figure>
      </div>
    </>
  );
};

export default Section6;
