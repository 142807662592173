import "./service.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const Consulting = () => {
  return(
    <>
  <Header />
  <div className="banner">
    <div className="headerArea">
      <h1 className="headerText">Consulting Service</h1>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#fff"
        fillOpacity="1"
        d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
      ></path>
    </svg>
  </div>

<div>
<div className="features">
        <div className="feature">
            <i className="fa-regular fa-star featureIcon"></i>
            <span className="featureTitle">Reviews</span>
            <span className="featureDesc">We received 100 +  5-Star
            Reviews</span>
        </div>
        <div className="feature">
        <i className="fa-regular fa-thumbs-up featureIcon"></i>     
            <span className="featureTitle">Projects</span>
            <span className="featureDesc">We have delivered 100+ Challenging
            Projects </span>
        </div>
        <div className="feature">
            <i className="fa-regular fa-paper-plane featureIcon"></i>         
             <span className="featureTitle">Years</span>
            <span className="featureDesc"> 8+ Years-Providing
            IT Consultation</span>
        </div>
        <div className="feature">
            <i className="fa-solid fa-trophy featureIcon"></i>
            <span className="featureTitle">People</span>
            <span className="featureDesc">We have Highly Skilled
            Creative Brains</span>
        </div>
    </div>
</div>
  <div className="consultancyContainer">
    <div className="a-left">
      <div className="a-card imgBack"></div>
      <div className="a-card">
        <img className="a-img" 
        src="/consult3.png" 
        alt="consultImg" />
      </div>
    </div>
    <div className="a-right">
    <h1 className="a-title">Consulting Us</h1>
        <p className="a-sub">
        Approach to Your Top IT Consulting Firm
        </p>
        <p className="a-desc">
        If your business is not into core Information technology and you are trying to overcome IT challenges, then it can cause your business to suffer with a huge cost. We provide comprehensive IT and technology consulting services that lead your business
         forward quickly to keep pace with today’s fast-moving IT landscape and competitive era.
        </p>
        <div className="a-award">
          <img src="/consult.jpg" alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">Why JR Global for IT Consulting Services?</h4>
            <p className="a-award-desc">
            As a top IT consulting services company, we combine modern-age technology services with end-to-end solution implementation to deliver enhanced performance
            </p>
          </div>
        </div>
    </div>
  </div>

  <Footer />
  </>
  )
}

export default Consulting