import "../styles/section4.css";

const Section4 = () => {
return(
<div className="container">
<h2 className="service4Text">Our Services</h2>
        <div className="serviceWrapper">
        
              <div className="row">
                <div className="service col-md-4">
                  <i className="fa-solid fa-code"></i>
                  <h3>App Development</h3>
                  <p> The creation, design, deployment, and maintenance of software applications tailored to meet specific business needs or to solve particular problems.</p>
                </div>
                <div className="service col-md-4">
                  <i className="fa-solid fa-laptop-code"></i>
                  <h3>Web Development</h3>
                  <p>
                    We focus on building applications for different platforms, including web, mobile, desktop, and cloud environments, and can range from simple tools to complex, enterprise-grade systems.
                  </p>
                </div>
                <div className="service col-md-4">
                  <i className="fa-solid fa-cloud"></i>
                  <h3>Cloud Migration</h3>
                  <p>This migration can involve transferring workloads to public clouds (e.g., Amazon Web Services (AWS), Microsoft Azure, Google Cloud), private clouds, or hybrid cloud solutions.</p>
                </div>
                </div>
                <div className="row">
                <div className="service col-md-4">
                  <i className="fa-solid fa-code-fork"></i>
                  <h3>Network & Security</h3>
                  <p>This is essential for ensuring the stability, reliability, and protection of an organization's IT infrastructure. 
                    These services enable businesses to operate securely and maintain business continuity.
                  </p>
                </div>
                <div className="service col-md-4">
                  <i className="fa-solid fa-handshake"></i>
                  <h3>IT Consulting</h3>
                   <p>This services refer to a broad range of advisory services that help businesses assess, implement, and optimize their information technology (IT) strategies and systems.</p>
                </div>
                <div className="service col-md-4">
                  <i className="fa-brands fa-codepen"></i>
                  <h3>IT Infrastructure</h3>
                  <p>IT Infra, refer to the set of services, components, and technologies that organizations rely on to deliver and manage their IT capabilities. </p>
                </div>
              </div>
        </div>
</div>
)
};

export default Section4;
