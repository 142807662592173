import { Container, Nav, Navbar,NavDropdown } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../styles/header.css';
import { useState } from 'react';

function Header() {

  const [nav, setNav] = useState(false);

  const changeValsueOnScroll = () =>{
    const scrollValue = document?.documentElement?.scrollTop;
    scrollValue > 100 ? setNav(true) : setNav(false);
  }
  window.addEventListener("scroll", changeValsueOnScroll)

  return (
    <header>
      <Navbar collapseOnSelect expand="sm" className={`${nav=== true ? "sticky" : ""}`}>
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/" className='logo'>
          <img src='/images/logo.png' alt='logo' className='img-fluid' />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">HOME</Nav.Link>
            <NavDropdown title="SERVICES" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/service/application">Application </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/infrastructure">Infrastructure</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/cloud">Cloud</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/network">Network</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/security">Security</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/datacenter">Data Center</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/virtualization">Virtualization</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/service/consulting">IT Consulting</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/casestudy">CASE STUDY</Nav.Link>
            <Nav.Link as={Link} to="/about">ABOUT</Nav.Link>
            <Nav.Link as={Link} to="/contact">CONTACT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </header> 
 )
}

export default Header