import React from 'react';
import '../styles/section2.css';

const images = [
    "/images/aws.png",
    "/images/chips.jpg",
    "/images/gc.webp",
    "/images/trend.png",
    "/images/nhm.png",
    "/images/vmware.png",
];

const Marquee = () => {
  return (
    <div className="marquee">
      <div className="marquee-inner">
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index + 1}`} />
        ))}
        {images.map((src, index) => (
          <img key={index + images.length} src={src} alt={`Slide ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default Marquee;