import "../services/service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Casestudy = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Case Study</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="caseContainer">
        <p>
          Case studies are a great way to exhibit real-case scenarios and assist
          your customers in accomplishing their goals. These are not just case
          studies but tips on how to use technology as a powerful asset in
          strengthening your business.
        </p>
        <div class="row">
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/s1.jpg" className="card-img-top" alt="card1" />
              <div className="card-body">
                <h5 className="card-title">NHM - Cloud Infrastructure</h5>
                <p className="cardText">
                Cloud infrastructure provides the foundational technology to support modern computing needs, allowing organizations to access 
                computing resources (compute, storage, and networking) without the burden of managing physical hardware.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/s5.jpg" className="card-img-top" alt="card5" />
              <div className="card-body">
                <h5 className="card-title">
                  Public Financial Management System
                </h5>
                <p className="cardText">
                  PFMS Scheme was rolled-out by the Controller General of
                  Accounts (CGA) at the behest of Finance Ministry, Department
                  of Expenditure as a Public Finance Management (PFM) reform.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/power.jpg" className="card-img-top" alt="card11" />
              <div className="card-body">
                <h5 className="card-title">Power</h5>
                <p className="cardText">
                  Data Management, Digital Transformation, Infrastructure,
                  Modernization, Security, Smart Analytics
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/s4.webp" className="card-img-top" alt="card4" />
              <div className="card-body">
                <h5 className="card-title">National Crime Records Bureau</h5>
                <p className="cardText">
                  The National Crime Records Bureau, abbreviated to NCRB, is an
                  Indian government agency responsible for collecting and
                  analysing crime data.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/s5.jpg" className="card-img-top" alt="card5" />
              <div className="card-body">
                <h5 className="card-title">
                  Public Financial Management System
                </h5>
                <p className="cardText">
                  PFMS Scheme was rolled-out by the Controller General of
                  Accounts (CGA) at the behest of Finance Ministry, Department
                  of Expenditure as a Public Finance Management (PFM) reform.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/s6.jpg" className="card-img-top" alt="card6" />
              <div className="card-body">
                <h5 className="card-title">National Informatics Centre</h5>
                <p className="cardText">
                  National Informatics Centre (NIC), established in 1976, is the
                  premier science and technology organization under Government
                  of India and majorly works for informatics services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/slide2.jpg" className="card-img-top" alt="card7" />
              <div className="card-body">
                <h5 className="card-title">
                  Centre for Railway Information Systems
                </h5>
                <p className="cardText">
                  The Centre for Railway Information Systems (CRIS) designs,
                  develops, implements and maintains most of the important
                  information systems of Indian Railways.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/slide4.jpg" className="card-img-top" alt="card8" />
              <div className="card-body">
                <h5 className="card-title">Hero FinCorp</h5>
                <p className="cardText">
                  Hero FinCorp (an associate company of Hero MotoCorp), is an
                  Indian Non-Banking Financial Company (NBFC). The company is
                  currently engaged in consumer finance businesses and
                  commercial lending.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/s8.webp" className="card-img-top" alt="card9" />
              <div className="card-body">
                <h5 className="card-title">
                  Unique Identification Authority of India (UIDAI)
                </h5>
                <p className="cardText">
                  The Unique Identification Authority of India (UIDAI) is a
                  statutory authority established under the provisions of the
                  Aadhaar Act, 2016 on 12 July 2016 by the Government of India,
                  under the Ministry of Electronics and Information Technology
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div className="card border-0">
              <img
                src="/workpeople.jpg"
                className="card-img-top"
                alt="card10"
              />
              <div className="card-body">
                <h5 className="card-title">PeopleStrong</h5>
                <p className="cardText">
                  Peoplestrong Hr Services Private Limited is classified as
                  Non-govt company and is registered at Registrar of Companies,
                  Delhi. It offers multi-process human resource (HR) outsourcing
                  and technology solutions.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/power.webp" className="card-img-top" alt="card11" />
              <div className="card-body">
                <h5 className="card-title">Power</h5>
                <p className="cardText">
                  Data Management, Digital Transformation, Infrastructure,
                  Modernization, Security, Smart Analytics
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div className="card border-0">
              <img src="/finance.jpg" className="card-img-top" alt="card12" />
              <div className="card-body">
                <h5 className="card-title">Finance Tool</h5>
                <p className="cardText">
                  Application Modernization, Digital Transformation,
                  Infrastructure Modernization, Migration, Security.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default Casestudy;
