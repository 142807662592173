import "../styles/section3.css";

function Section3() {
  return (
    <div className="section3Wrapper blog">
      <div className="container">
        <div className="blog-head text-center">
          <h2>Cloud Migration</h2>
          <h6>Key Components of Cloud Migration</h6>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="/e1.jpg" alt="img1" />
                  <h5 className="textBottom">	Strategy</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
               Cloud Migration Strategy<br/>
                 
                  <hr/>Before migration, businesses need to decide on the most suitable cloud environment 
                  (public, private, or hybrid) and the specific services (IaaS, PaaS, SaaS) to adopt. 
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="/e2.webp" alt="img2" />
                  <h5 className="textBottom">Phases</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
              Cloud Migration Phases<br/>
            <hr/> 
             1) Planning and Assessment,<br/> 2)Design and Architecture, <br/>3) Migration Execution, <br/>4) Optimization and Testing,
             <br/>5) Post-Migration, <br/>6) Review and Future Planning.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e3.jpg" alt="img3" />
    <h5 className="textBottom">Models</h5>             
     <div className="overlay bottom-overlay">
                <div className="p-text">
                	Cloud Migration Models<br/>
            <hr/> refers to the different approaches or strategies organizations can use to move their IT
             infrastructure, applications, data, and workloads from on-premises environments to the cloud. 
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e4.webp" alt="img4" />
                  <h5 className="textBottom">Tools</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
                Cloud Migration Tools & Services<br/><hr/>
              	 Several tools & services are available to facilitate cloud migration, making the process 
                 more efficient, secure, & cost-effective. These tools assist with various aspects, such as data transfer, security, & application migration.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e5.webp" alt="img5" />
                  <h5 className="textBottom">Security</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text"> 
                Security Considerations<br/><hr/>
                	Security is a critical concern when migrating to the cloud.The following practices can help 
                  mitigate risks:Data Encryption,Identity & Access Management,Compliance,Backup & Disaster Recovery.

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e6.webp" alt="img6" />
                  <h5 className="textBottom">Benefits </h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
                Cloud Migration Benefits<br/><hr/>
                1) Cost Efficiency, <br/>2) Scalability & Flexibility, <br/>3) Improved Performance & Reliability,
                <br/>4) Enhanced Security, <br/>5) Business Agility & Innovation,<br/> 6) Simplified IT Mgmt. & maintenance.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
