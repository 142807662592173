
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Application from './services/Application'
import Cloud from './services/Cloud'
import Infrastructure from './services/Infrastructure';
import Network from './services/Network';
import Security from './services/Security';
import Virtualization from './services/Virtualization';
import Datacenter from './services/Datacenter';
import Consulting from './services/Consulting';
import Casestudy from './pages/Casestudy.js';
import ScrollToTop from './components/ScrollToTop.js';

function App() {
  return (
    <BrowserRouter>
        <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/casestudy' element={<Casestudy/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/service/application' element={<Application/>} />
        <Route path='/service/cloud' element={<Cloud/>} />
        <Route path='/service/network' element={<Network/>} />
        <Route path='/service/security' element={<Security/>} />
        <Route path='/service/datacenter' element={<Datacenter />} />
        <Route path='/service/infrastructure' element={<Infrastructure/>} />
        <Route path='/service/virtualization' element={<Virtualization/>} />
        <Route path='/service/consulting' element={<Consulting/>} />
        <Route path="*" element={<h3><code>Page You Requested Not Found</code></h3>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
